// import Permissions from '@/security/permissions'

const state = () => {
  return {
    menu: [
      {
        icon: 'QrCodeIcon',
        pageName: 'scan',
        title: 'scan'
        // permission: Permissions.values.adminRead
      },
      {
        icon: 'LogsIcon',
        pageName: 'logs',
        title: 'logs'
        // permission: Permissions.values.userRead
      },
      'devider'
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
