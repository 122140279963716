import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import i18n from './vueI18n'
import './libs'
import 'epic-spinners/dist/lib/epic-spinners.min.css'
import 'material-icons/iconfont/material-icons.css'
// SASS Theme
import './assets/sass/app.scss'
import CKEditor from 'ckeditor4-vue'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { addIcons } from 'oh-vue-icons'
import { FaFlag, RiZhihuFill } from 'oh-vue-icons/icons'

addIcons(FaFlag, RiZhihuFill)

// import { firestorePlugin } from 'vuefire'

/* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */

/* add icons to the library */
library.add(faUserSecret, faTwitter)

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(CKEditor)

globalComponents(app)
utils(app)

app.mount('#app')
