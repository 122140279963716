import firebaseInit from '@/shared/firebase/firebase-init'
import config from '@/config'
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'
import crypto from '@/shared/crypto'
import GraphqlClient from '@/shared/graphql/client'
import gql from 'graphql-tag'

const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlAdmin)

export default class AdminService {
  static init() {
    return firebaseInit()
  }

  static onAuthStateChanged(callbackSuccess, callbackError) {
    return onAuthStateChanged(getAuth(), callbackSuccess, callbackError)
  }

  static async reauthenticateWithStorageToken() {
    try {
      let token = crypto.decryptString(
        localStorage.getItem('token'),
        'secret-token'
      )
      if (!token) {
        token = await this.generateCustomToken()
      }

      try {
        return await signInWithCustomToken(getAuth(), token)
      } catch (error) {
        if (error && error.code && error.code === 'auth/invalid-custom-token') {
          token = await this.generateCustomToken()
          return signInWithCustomToken(getAuth(), token)
        }
        throw error
      }
    } catch (error) {
      console.error({ code: error.code, message: error.message })
    }
  }

  static async generateCustomToken() {
    try {
      const response = await graphqlClient.query({
        query: gql`
          {
            authStorageTokenGate
          }
        `
      })

      const token = response.data.authStorageTokenGate

      localStorage.setItem('token', crypto.encryptString(token, 'secret-token'))
      return token
    } catch (error) {
      console.error(error)
    }
  }

  static async fetchMe() {
    const response = await graphqlClient.query({
      query: gql`
        {
          findMeGate {
            createdAt
            createdBy
            email
            id
            isRemoved
            lang
            updatedAt
            updatedBy
            userName
          }
        }
      `
    })

    return response.data.findMeGate
  }

  static async signinWithEmailAndPassword(email, password, rememberMe = false) {
    const auth = getAuth()
    rememberMe
      ? await setPersistence(auth, browserLocalPersistence)
      : await setPersistence(auth, browserSessionPersistence)

    return await signInWithEmailAndPassword(auth, email, password)
  }

  static signout() {
    return signOut(getAuth())
  }

  static fetchLocalCurrentUser() {
    const currentUser = localStorage.getItem('currentUser')
    return currentUser ? crypto.decryption(currentUser, 'secret-c-u') : null
  }

  static saveLocalCurrentUser(currentUser) {
    if (currentUser) {
      localStorage.setItem(
        'currentUser',
        crypto.encryption(currentUser, 'secret-c-u')
      )
    }
  }

  static async sendPasswordResetEmail(email) {
    const auth = getAuth()
    auth.languageCode = localStorage.getItem('language') || 'ar'
    await sendPasswordResetEmail(auth, email)
  }

  static async updateProfile({
    userName,
    phoneNumber,
    avatar,
    pagesAccess,
    lang
  }) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EDIT_ME_ADMIN($adminUpdate: AdminUpdate!) {
          editMeAdmin(adminUpdate: $adminUpdate)
        }
      `,
      variables: {
        adminUpdate: {
          userName,
          phoneNumber,
          avatar,
          pagesAccess,
          lang
        }
      }
    })
    return response.data.editMeAdmin
  }

  static async changeMyPassword(oldPassword, newPassword) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_CHANGE_PROFILE_PASSWORD(
          $oldPassword: String!
          $newPassword: String!
        ) {
          resetMyPassword(oldPassword: $oldPassword, newPassword: $newPassword)
        }
      `,

      variables: {
        oldPassword,
        newPassword
      }
    })

    return response.data.resetMyPassword
  }

  static async fetchAdmins(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_ADMINS($filter: [Filter]!, $pagination: PaginationInput!) {
          listAdmins(filter: $filter, pagination: $pagination) {
            id
            email
            userName
            phoneNumber
            avatar
            lang
            role
            status
            isRemoved
            pagesAccess
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })
    return response.data.listAdmins
  }

  static async find(adminId) {
    const response = await graphqlClient.query({
      query: gql`
        query ADMIN_FIND($adminId: String!) {
          findAdmin(adminId: $adminId) {
            id
            email
            userName
            phoneNumber
            avatar
            lang
            role
            status
            isRemoved
            pagesAccess
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        adminId
      }
    })

    return response.data.findAdmin
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_ADMIN($adminInput: AdminInput!) {
          createAdmin(adminInput: $adminInput)
        }
      `,
      variables: {
        adminInput: data
      }
    })
    if (response.data.createAdmin && response.data.createAdmin.status) {
      return response.data.createAdmin.result
    } else {
      throw response.data.createAdmin.error
    }
  }

  static async edit(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EDIT_ADMIN($adminId: String!, $adminUpdate: AdminUpdate!) {
          editAdmin(adminId: $adminId, adminUpdate: $adminUpdate)
        }
      `,
      variables: {
        adminId: id,
        adminUpdate: data
      }
    })
    if (response.data.editAdmin && response.data.editAdmin.status) {
      return response.data.editAdmin.result
    } else {
      throw response.data.editAdmin.error
    }
  }

  static async removeAdmins(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_REMOVE($adminsIds: [String]!) {
          removeAdmins(adminsIds: $adminsIds)
        }
      `,

      variables: {
        adminsIds: ids
      }
    })
    if (response.data.removeAdmins && response.data.removeAdmins.status) {
      return response.data.removeAdmins.result
    } else {
      console.error(response.data.removeAdmins.error)
      throw response.data.removeAdmins.error
    }
  }

  static async enable(ids) {
    return this._changeStatus(ids, true)
  }

  static async disable(ids) {
    return this._changeStatus(ids, false)
  }

  static async _changeStatus(ids, enabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_CHANGE_STATUS(
          $adminsIds: [String!]!
          $status: Boolean!
        ) {
          setAdminsStatus(adminsIds: $adminsIds, status: $status)
        }
      `,

      variables: {
        adminsIds: ids,
        status: !!enabled
      }
    })

    if (response.data.setAdminsStatus && response.data.setAdminsStatus.status) {
      return response.data.setAdminsStatus.result
    } else {
      console.error(response.data.setAdminsStatus.error)
      throw response.data.setAdminsStatus.error
    }
  }

  // static async sendWelcomeEmail(email, name) {
  //   return this.sendWelcomeEmailFromBackend(email, name)
  // }
  // static async sendWelcomeEmailFromBackend(email, name) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation AUTH_SEND_WELCOME_EMAIL($email: String!, $name: String) {
  //         authSendWelcomeEmail(email: $email, name: $name)
  //       }
  //     `,
  //     variables: {
  //       email,
  //       name
  //     }
  //   })

  //   const res = response.data.authSendWelcomeEmail
  //   return res
  // }

  // static async fetchMe() {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       {
  //         authMe {
  //           id
  //           authenticationUid
  //           fullName
  //           firstName
  //           lastName
  //           phoneNumber
  //           email
  //           roles
  //           avatars {
  //             id
  //             name
  //             publicUrl
  //           }
  //         }
  //       }
  //     `
  //   })

  //   return response.data.authMe
  // }

  // static async isEmailConfigured() {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       {
  //         authIsEmailConfigured
  //       }
  //     `
  //   })

  //   return response.data.authIsEmailConfigured
  // }

  // static async reauthenticateWithStorageToken() {
  //   try {
  //     // debugger;
  //     let token = localStorage.getItem('token')
  //     if (!token) {
  //       const response = await graphqlClient.query({
  //         query: gql`
  //           {
  //             authStorageToken
  //           }
  //         `
  //       })

  //       token = response.data.authStorageToken
  //       localStorage.setItem('token', this.encryptString(token, 'secret token'))
  //     } else {
  //       token = this.decryptString(token, 'secret token')
  //     }

  //     return firebase.auth().signInWithCustomToken(token)
  //   } catch (error) {
  //     // console.error(error);
  //     // throw error
  //   }
  // }

  // #region [ Encryption Functions ]
  // ================================================================= //
  //                          ENCRYPT OBJECT                           //
  // ================================================================= //
  // static encryptObject(object, key) {
  //   const cryptoJSON = require('crypto-json')
  //   const algorithm = 'aes256'
  //   const encoding = 'hex'
  //   const password = key
  //   let keys = []

  //   for (let [k, value] of Object.entries(object)) {
  //     if (value) {
  //       keys.push(k)
  //     }
  //   }
  //   const output = cryptoJSON.encrypt(object, password, {
  //     encoding,
  //     keys,
  //     algorithm
  //   })
  //   return output
  // }
  // ================================================================= //
  //                          DECRYPT OBJECT                           //
  // ================================================================= //
  // static decryptObject(encryptedObject, key) {
  //   const cryptoJSON = require('crypto-json')
  //   const algorithm = 'aes256'
  //   const encoding = 'hex'
  //   const password = key
  //   let keys = []

  //   for (let [k, value] of Object.entries(encryptedObject)) {
  //     if (value) {
  //       keys.push(k)
  //     }
  //   }

  //   const output = cryptoJSON.decrypt(encryptedObject, password, {
  //     encoding,
  //     keys,
  //     algorithm
  //   })
  //   return output
  // }

  // ================================================================= //
  //                          ENCRYPT STRING                           //
  // ================================================================= //
  // static encryptString(message, key) {
  //   const CryptoJS = require('crypto-js')

  //   // Encrypt
  //   var ciphertext = CryptoJS.AES.encrypt(message, key)
  //   return ciphertext
  // }

  // ================================================================= //
  //                          DECRYPT STRING                           //
  // ================================================================= //
  // static decryptString(ciphertext, key) {
  //   const CryptoJS = require('crypto-js')

  //   // Decrypt
  //   var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), key)
  //   var plaintext = bytes.toString(CryptoJS.enc.Utf8)
  //   return plaintext
  // }
  // ================================================================= //
  //                      Object ENCRYPTION OTHER WAY                  //
  // ================================================================= //
  // static encryption(data, key) {
  //   const CryptoJS = require('crypto-js')

  //   // Encrypt
  //   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key)
  //   return ciphertext
  // }

  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  // static decryption(cipherData, key) {
  //   var CryptoJS = require('crypto-js')

  //   // Decrypt
  //   var bytes = CryptoJS.AES.decrypt(cipherData.toString(), key)
  //   var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  //   return decryptedData
  // }
  // #endregion
}
