<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
      <form
        id="tabulator-html-filter-form"
        class="xl:flex"
        :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'"
        v-if="filterFields.length"
      >
        <div
          class="sm:flex items-center"
          :class="isRTL ? 'sm:ml-4' : 'sm:mr-4'"
        >
          <label class="w-24 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{
            i18n('common.table.filter.field')
          }}</label>
          <select
            v-model="selectedField"
            @change="resetAll()"
            class="
              form-select
              w-full
              sm:w-32
              2xl:w-full
              mt-2
              sm:mt-0
              dark:bg-dark-9
            "
          >
            <option
              v-for="(field, index) in filterFields"
              :key="index"
              :value="field"
            >
              {{ field.label }}
            </option>
          </select>
        </div>

        <div
          class="sm:flex items-center mt-2 xl:mt-0"
          :class="isRTL ? 'sm:ml-4' : 'sm:mr-4'"
        >
          <input
            v-if="selectedField.type == 'text' || selectedField.type === 'array'"
            v-model="filterValue"
            @keydown.enter.exact.prevent="doFilter"
            type="text"
            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
          />

          <input
            v-if="selectedField.type == 'number'"
            v-model="filterValue"
            @keydown.enter.exact.prevent="doFilter"
            type="number"
            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
          />

          <div v-if="selectedField.type == 'number-range'">
            <div class="flex items-center gap-2">
              <label for="">{{ i18n('common.from') }}</label>
              <input
                v-model="filterValueFrom"
                @keydown.enter.exact.prevent="doFilter"
                type="number"
                class="
                  form-control
                  sm:w-32
                  2xl:w-full
                  mt-2
                  sm:mt-0
                  dark:bg-dark-9
                "
              />
              <label for="">{{ i18n('common.to') }}</label>
              <input
                v-model="filterValueTo"
                @keydown.enter.exact.prevent="doFilter"
                type="number"
                :min="filterValueFrom"
                class="
                  form-control
                  sm:w-32
                  2xl:w-full
                  mt-2
                  sm:mt-0
                  dark:bg-dark-9
                "
              />
            </div>
          </div>

          <TomSelect
            v-if="selectedField.type == 'boolean'"
            v-model="filterValue"
            class="w-full sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
          >
            <option :value="true" class="">{{ true }}</option>
            <option :value="false">{{ false }}</option>
          </TomSelect>

          <div v-if="selectedField.type === 'list'">
            <TomSelect
              v-model="filterValue"
              class="w-full sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
            >
              <option
                v-for="(option, index) in selectedField.options"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </TomSelect>
          </div>

          <!-- <select v-if="selectedField.type == 'boolean'"
          v-model="filterValue"
          class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
        >
          <option :value="true" selected>{{ true }}</option>
          <option :value="false">{{ false }}</option>
        </select> -->

          <div v-if="selectedField.type == 'date'">
            <div class="preview">
              <div class="relative w-56 mx-auto">
                <div
                  class="
                    absolute
                    rounded-l
                    w-10
                    h-full
                    flex
                    items-center
                    justify-center
                    bg-gray-100
                    border
                    text-gray-600
                    dark:bg-dark-1 dark:border-dark-4
                  "
                >
                  <CalendarIcon class="w-4 h-4" />
                </div>
                <Litepicker
                  v-model="filterValue"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control"
                  :class="isRTL ? 'pr-12' : 'pl-12'"
                />
              </div>
            </div>
          </div>

          <div v-if="selectedField.type == 'date-range'">
            <div class="w-full sm:w-48 2xl:w-full mt-2 sm:mt-0">
              <Litepicker
                v-model="filterValue"
                v-if="isRTL"
                :options="{
                  lang: 'ar',
                  buttonText: {
                    apply: 'طبق',
                    cancel: 'إلغاء'
                  },
                  tooltipText: {
                    one: 'يوم',
                    two: 'يومان',
                    few: 'أيام',
                    many: 'أيام'
                  },
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control dark:bg-dark-9"
              />
              <Litepicker
                v-model="filterValue"
                v-else
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control dark:bg-dark-9"
              />
            </div>
          </div>
        </div>

        <div id="form-actions" class="mt-5 xl:mt-0 flex items-center gap-2">
          <button
            type="button"
            class="btn bg-theme-31 text-white w-full sm:w-24"
            :disabled="disabled"
            @click="doFilter()"
          >
            {{ i18n('common.table.filter.go') }}
          </button>
          <button
            type="button"
            class="btn bg-theme-32 text-white w-full sm:w-24"
            :disabled="disabled"
            @click="$emit('reset')"
          >
            {{ i18n('common.table.filter.reset') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// const inputTypes = ['text', 'number', 'date', 'date-range', 'email', '']

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default defineComponent({
  props: {
    filterFields: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedField: this.filterFields.length ? this.filterFields[0] : '',
      filterValue: '',
      filterValueFrom: 0,
      filterValueTo: 0
      // filter: {
      //   key: '',
      //   operator: 'equal',
      //   value: ''
      // },
      // inputType: 'text',
      // inputTypes: [
      //   { label: 'Text', value: 'text' },
      //   { label: 'Number', value: 'number' },
      //   { label: 'Boolean', value: 'boolean' },
      //   { label: 'Date', value: 'date' },
      //   { label: 'DateTime', value: 'datetime-locale' },
      //   { label: 'Date-Range', value: 'date-range' },
      //   { label: 'Number-Range', value: 'number-range' }
      // ],
      // operations: [
      //   { label: '<', value: 'less' },
      //   { label: '<=', value: 'lessEqual' },
      //   { label: '==', value: 'equal' },
      //   { label: '>', value: 'greater' },
      //   { label: '>=', value: 'greaterEqual' },
      //   { label: '!=', value: 'notEqual' },
      //   { label: 'Start with', value: 'startsWith' }
      // ]
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
    // operator() {
    //   if (this.selectedField.type == 'text') {
    //     return 'startsWith'
    //   } else if (this.selectedField.type == 'date-range') {
    //     return ''
    //   }
    //   return 'equal'
    // }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    resetAll() {
      this.filterValue = ''
    },
    doFilter() {
      let filter
      let orderBy = null
      if (this.selectedField.type === 'date') {
        const date = dayjs(this.filterValue).utc(true).format()
        filter = {
          key: this.selectedField.value,
          operator: 'equal',
          value: date
        }
      } else if (this.selectedField.type === 'date-range') {
        const dates = this.filterValue.split(' - ')
        const firstDay = dayjs(dates[0]).utc(true).format()
        const lastDay = dayjs(dates[1]).utc(true).format()
        filter = [
          {
            key: this.selectedField.value,
            operator: 'greaterEqual',
            value: firstDay
          },
          {
            key: this.selectedField.value,
            operator: 'lessEqual',
            value: lastDay
          }
        ]
        orderBy = this.selectedField.value
      } else if (this.selectedField.type === 'number-range') {
        filter = [
          {
            key: this.selectedField.value,
            operator: 'greaterEqual',
            value: this.filterValueFrom.toString()
          },
          {
            key: this.selectedField.value,
            operator: 'lessEqual',
            value: this.filterValueTo.toString()
          }
        ]
        orderBy = this.selectedField.value
      } else if (this.selectedField.type === 'array') {
        filter = {
          key: this.selectedField.value,
          operator: 'arrayContains',
          value: this.filterValue
        }
      } else {
        filter = {
          key: this.selectedField.value,
          operator: 'equal',
          value: this.filterValue
        }
      }
      this.$emit('filter', filter, orderBy)
    }
  }
})
</script>

<style lang="scss">
.tom__select {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.375rem !important;
  border-width: 1px !important;
}
</style>
