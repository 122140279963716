import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import layout from '@/store/layout-store'
import auth from '@/store/auth/auth-store'
import log from '@/store/log/log-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    auth,
    log
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
