/* eslint-disable */
import AdminService from '@/store/auth/auth-service'
import router from '@/router'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import { doc, onSnapshot, getFirestore } from 'firebase/firestore'

const state = {
  authenticationUser: null,
  currentUser: null,
  loadingInit: true,
  loadingEmailConfirmation: false,
  loadingPasswordResetEmail: false,
  loadingUpdateProfile: false,
  loadingChangeMyPassword: false,
  loading: false
}

const getters = {
  authenticationUser: state => state.authenticationUser,
  currentUser: state => state.currentUser,
  currentUserEmail: (state, getters) =>
    getters.currentUser ? getters.currentUser.email : null,
  currentUserFullName: (state, getters) =>
    getters.currentUser ? getters.currentUser.fullName : '',

  signedIn: (state, getters) =>
    !!getters.currentUser && !!getters.currentUser.id,

  roles: (state, getters) =>
    getters.currentUser ? getters.currentUser.roles || [] : [],

  role: (state, getters) =>
    getters.currentUser ? getters.currentUser.role || null : null,

  emptyPermissions: (state, getters) => !getters.roles || !getters.roles.length,

  loading: state => !!state.loading,

  loadingInit: state => !!state.loadingInit,

  loadingEmailConfirmation: state => !!state.loadingEmailConfirmation,

  loadingPasswordResetEmail: state => !!state.loadingPasswordResetEmail,

  loadingUpdateProfile: state => !!state.loadingUpdateProfile,

  currentUserNameOrEmailPrefix: (state, getters) => {
    if (!getters.currentUser) {
      return null
    }

    if (
      getters.currentUserFullName &&
      getters.currentUserFullName.length < 25
    ) {
      return getters.currentUserFullName
    }

    if (getters.currentUser.firstName) {
      return getters.currentUser.firstName
    }

    return getters.currentUser.email.split('@')[0]
  },

  currentUserAvatar: (state, getters) => {
    if (
      !getters.currentUser ||
      !getters.currentUser.avatars ||
      !getters.currentUser.avatars.length ||
      !getters.currentUser.avatars[0].publicUrl
    ) {
      return null
    }

    return getters.currentUser.avatars[0].publicUrl
  }
}

const mutations = {
  AUTH_START(state) {
    state.loading = true
  },
  AUTH_SUCCESS(state, payload) {
    state.authenticationUser = payload.authenticationUser || null
    state.currentUser = payload.currentUser || null
    state.loading = false
  },
  AUTH_ERROR(state) {
    state.authenticationUser = null
    state.currentUser = null
    state.loading = false
  },

  CURRENT_USER_REFRESH_SUCCESS(state, payload) {
    state.currentUser = payload.currentUser || null
  },

  AUTH_INIT_SUCCESS(state, payload) {
    state.authenticationUser = payload.authenticationUser || null
    state.currentUser = payload.currentUser || null
    state.loadingInit = false
  },

  AUTH_INIT_ERROR(state) {
    state.authenticationUser = null
    state.currentUser = null
    state.loadingInit = false
  },

  PASSWORD_RESET_START(state) {
    state.loadingPasswordResetEmail = true
  },

  PASSWORD_RESET_SUCCESS(state) {
    state.loadingPasswordResetEmail = false
  },

  PASSWORD_RESET_ERROR(state) {
    state.loadingPasswordResetEmail = false
  },

  UPDATE_PROFILE_START(state) {
    state.loadingUpdateProfile = true
  },
  UPDATE_PROFILE_SUCCESS(state) {
    state.loadingUpdateProfile = false
  },
  UPDATE_PROFILE_ERROR(state) {
    state.loadingUpdateProfile = false
  },
  CHANGE_MY_PASSWORD_START(state) {
    state.loadingChangeMyPassword = true
  },
  CHANGE_MY_PASSWORD_SUCCESS(state) {
    state.loadingChangeMyPassword = false
  },
  CHANGE_MY_PASSWORD_ERROR(state) {
    state.loadingChangeMyPassword = false
  }
}

const actions = {
  async doInit({ commit, dispatch }) {
    await AdminService.init()
    const currentUser = AdminService.fetchLocalCurrentUser()

    if (!currentUser) {
      commit('AUTH_INIT_SUCCESS', {
        currentUser: null,
        authenticationUser: null
      })
      return
    }

    const unsubscribe = AdminService.onAuthStateChanged(
      authenticationUser => {
        dispatch('doSigninFromAuthChange', authenticationUser)
        unsubscribe()
      },
      error => {
        console.error(error)
        commit('AUTH_INIT_ERROR')
      }
    )
  },

  async doWaitUntilInit({ getters }) {
    if (!getters.loadingInit) {
      return Promise.resolve()
    }
    return new Promise(resolve => {
      const waitUntilInitInterval = setInterval(() => {
        if (!getters.loadingInit) {
          clearInterval(waitUntilInitInterval)
          resolve()
        }
      }, 500)
    })
  },

  async doSigninFromAuthChange({ commit }, authenticationUser) {
    try {
      const currentUser = await AdminService.fetchMe()

      AdminService.saveLocalCurrentUser(currentUser)

      if (authenticationUser) {
        AdminService.reauthenticateWithStorageToken()
        currentUser.emailVerified = !!authenticationUser.emailVerified
      }
      commit('AUTH_INIT_SUCCESS', {
        currentUser,
        authenticationUser
      })
    } catch (error) {
      AdminService.signout()

      Errors.handle(error)
      commit('AUTH_INIT_ERROR', error)
    }
  },

  async doSigninWithEmailAndPassword(
    { commit },
    { email, password, rememberMe }
  ) {
    try {
      commit('AUTH_START')
      let authenticationUser = null
      let currentUser = null
      const credentials = await AdminService.signinWithEmailAndPassword(
        email,
        password,
        rememberMe
      )
      if (credentials && credentials.user) {
        authenticationUser = credentials.user

        currentUser = await AdminService.fetchMe()

        currentUser.emailVerified = !!authenticationUser.emailVerified
      }

      AdminService.reauthenticateWithStorageToken()

      commit('AUTH_SUCCESS', { currentUser, authenticationUser })
      AdminService.saveLocalCurrentUser(currentUser)
      router.push('/')
    } catch (error) {
      console.error(error)
      await AdminService.signout()
      Errors.handle(error)
      commit('AUTH_ERROR')
    }
  },

  async doSendPasswordResetEmail({ commit }, email) {
    try {
      commit('PASSWORD_RESET_START')
      await AdminService.sendPasswordResetEmail(email)
      Message.success(i18n('auth.passwordResetEmailSuccess'))
      commit('PASSWORD_RESET_SUCCESS')
    } catch (error) {
      Error.handle(error)
      commit('PASSWORD_RESET_ERROR')
    }
  },

  async doUpdateProfile(
    { commit, dispatch },
    { userName, phoneNumber, avatar, email, pagesAccess, lang }
  ) {
    try {
      commit('UPDATE_PROFILE_START')
      let currentUser = AdminService.fetchLocalCurrentUser()
      if (currentUser) {
        currentUser.userName = userName || currentUser.userName
        currentUser.phoneNumber = phoneNumber || currentUser.phoneNumber
        currentUser.avatar = avatar || currentUser.avatar
        currentUser.email = email || currentUser.email
        currentUser.pagesAccess = pagesAccess || currentUser.pagesAccess
        currentUser.lang = lang || currentUser.lang

        AdminService.saveLocalCurrentUser(currentUser)
      }

      await AdminService.updateProfile({
        userName,
        phoneNumber,
        avatar,
        pagesAccess,
        lang
      })
      commit('UPDATE_PROFILE_SUCCESS')
      await dispatch('doRefreshCurrentUser')
      Message.success(i18n('auth.profile.success'))
      router.push('/')
    } catch (error) {}
  },

  async doRefreshCurrentUser({ commit, getters }) {
    try {
      const authenticationUser = getters.authenticationUser
      const currentUser = await AdminService.fetchMe()
      AdminService.saveLocalCurrentUser(currentUser)
      currentUser.emailVerified = !!authenticationUser.emailVerified
      AdminService.reauthenticateWithStorageToken()
      commit('CURRENT_USER_REFRESH_SUCCESS', { currentUser })
    } catch (error) {
      AdminService.signout()
      Errors.handle(error)
      commit('CURRENT_USER_REFRESH_ERROR', error)
    }
  },

  async doChangeMyPassword({ commit }, { oldPassword, newPassword }) {
    try {
      commit('CHANGE_MY_PASSWORD_START')
      const response = await AdminService.changeMyPassword(
        oldPassword,
        newPassword
      )
      if (response.status === true) {
        commit('CHANGE_MY_PASSWORD_SUCCESS')
        Message.success(i18n('auth.profile.changePassword'))
        router.push('/profile')
      } else {
        commit('CHANGE_MY_PASSWORD_ERROR')
        Message.error('error')
      }
    } catch (error) {
      Errors.handle(error)
      commit('CHANGE_MY_PASSWORD_ERROR')
    }
  },

  async doRunCurrentUserChangedListener({ getters, dispatch }) {
    try {
      const currentUser = getters.currentUser
      onSnapshot(doc(getFirestore(), 'admins', currentUser.id), doc => {
        const admin = doc.data()
        if (!admin || admin.status === 'disabled' || admin.isRemoved) {
          dispatch('doSignout')
        }
        dispatch('doRefreshCurrentUser')
      })
    } catch (error) {
      throw error
    }
  },

  async doSignout({ commit }) {
    try {
      commit('AUTH_START')
      await AdminService.signout()
      localStorage.clear()
      commit('AUTH_SUCCESS', {
        authenticationUser: null,
        currentUser: null
      })
      router.push('/auth/login')
    } catch (error) {
      // Errors.handle(error)
      commit('AUTH_ERROR')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
