/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'

//#region [ Main Pages ]
const SideMenu = () => import('@/layouts/side-menu/Main.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')
//#endregion

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
// const Profile = () => import('@/views/profile/profile.vue')
// const ChangePassword = () => import('@/views/profile/change-password.vue')

const ScanFormPage = () => import('@/views/scan/scan-form-page.vue')

const LogsListPage = () => import('@/views/logs/logs-list-page.vue')

const routes = [
  {
    path: '',
    component: SideMenu,
    meta: { auth: true },
    children: [
      // {
      //   name: 'profile',
      //   path: 'profile',
      //   component: Profile,
      //   meta: {
      //     auth: true
      //   }
      // },
      // {
      //   name: 'changePassword',
      //   path: 'change-password',
      //   component: ChangePassword,
      //   meta: {
      //     auth: true
      //   }
      // },
      {
        name: 'scan',
        path: '',
        alias: 'scan',
        component: ScanFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminRead
        }
      },
      {
        name: 'logs',
        path: 'logs',
        component: LogsListPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminCreate
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
  // { path: '*', redirect: '/404' }
]

//
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

// eslint-disable-next-line
import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

export function getPages(pages = routes) {
  const routesPages = []
  for (let i = 0; i < pages.length; i++) {
    const route = pages[i]
    if (route.name) {
      routesPages.push({ name: route.name, checked: false })
    }

    if (route.children && route.children.length) {
      const result = getPages(route.children, 0)
      routesPages.push(...result)
    }
  }
  return routesPages
}
export default router
