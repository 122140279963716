import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlLog)

export class LogService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LEAD_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          findMyLogs(filter: $filter, pagination: $pagination) {
            id
            visitorName
            client {
              id
              fullName
            }
            createdAt
            createdBy
            updatedBy
            updatedAt
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.findMyLogs
  }

  static async verifyQRCode(securityCode) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LEAD_DESTROY($securityCode: String!) {
          verifyQRCode(securityCode: $securityCode)
        }
      `,
      variables: {
        securityCode
      }
    })

    if (response.data.verifyQRCode && response.data.verifyQRCode.status) {
      return response.data.verifyQRCode.result
    } else {
      console.error(response.data.verifyQRCode.error)
      throw response.data.verifyQRCode.error
    }
  }
}
