const firebaseConfig = {
  apiKey: 'AIzaSyBHq8SEFdIYncZHDOUg3RZUZF4aXFSmNeA',
  authDomain: 'el-ghoneimy-service.firebaseapp.com',
  projectId: 'el-ghoneimy-service',
  storageBucket: 'el-ghoneimy-service.appspot.com',
  messagingSenderId: '52423920730',
  appId: '1:52423920730:web:218a638b6a0bac132fdc63',
  measurementId: 'G-GQNKG8K65V'
}

const REGION = 'europe-west1'
const BASE_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net`

// #region [ Cloud Functions ]
const backendUrl = BASE_URL
const backendUrlAdmin = `${BASE_URL}/gate`
const backendUrlLog = `${BASE_URL}/log`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrl,
  backendUrlAdmin,
  backendUrlLog
}
